import React from 'react'
import Layout from '../components/layout/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { ParallaxBanner } from 'react-scroll-parallax'
import SectionCard from '../components/layout/SectionCard'

import airImgPath from '../assets/images/regenerate/sam-schooler-air-unsplash.jpg'
import waterImgPath from '../assets/images/regenerate/anastasia-taioglou-water-unsplash.jpg'
import soilImgPath from '../assets/images/regenerate/Heliae-Native-Ad-2-14-22-Soil-Microbes-Photo-FB.jpg'
import grassImgPath from '../assets/images/regenerate/ochir-erdene-oyunmedeg-grass-unsplash.jpg'
import forestImgPath from '../assets/images/regenerate/fabrizio-conti-pineforest-unsplash.jpg'
import cowsImgPath from '../assets/images/regenerate/stijn-te-strake-cows-unsplash.jpg'
import regenerateImgPath from '../assets/images/regenerate/flowers-mountains-sunrise-wallpaper-preview.jpg'

const maxPictureWidth = 500

const sectionData = {
  air: {
    staticImg: (
      <StaticImage
        src="../assets/images/regenerate/sam-schooler-air-unsplash.jpg"
        alt="air"
        width={maxPictureWidth}
        layout="constrained"
        placeholder="blurred"
      />
    ),
    imgCredit: '',
  },
}

let helmut = {
  title: 'Treeland Trails Regenerate',
  description: [''].join('\n'),
  keywords: [''].join('\n'),
}

const Regenerate = () => {
  return (
    <Layout
      helmutTitle={helmut.title}
      helmutDescription={helmut.description}
      helmutKeywords={helmut.keywords}
    >
      <div id="regenerate" className="page-content">
        <div id="intro">
          <div className="inner">
            <header className="">
              <h1>Regenerate</h1>
            </header>
            It is our mission to restore this watershed back to ecological
            vibrancy. Using regenerative agriculture and other innovative
            techniques and management practices, we hope to improve the soil
            health and water-holding capacity, increase the biodiversity both
            above and within the soil, and improve the forest and woodland
            habitats.
            <p />
            <ParallaxBanner
              layers={[
                {
                  image: regenerateImgPath,
                  speed: -20,
                },
              ]}
              style={{ aspectRatio: '10' }}
            />
          </div>
        </div>

        <SectionCard
          imgPath={soilImgPath}
          innerBox="true"
          sectionStyle="floatRight"
        >
          <h2>Soil</h2>
          <p>
            A fundamental concept of regenerative agriculture is to increase the
            organic matter content of soils through practices such as cover
            cropping, composting, and crop rotation. Organic matter provides
            essential nutrients, improves soil structure, enhances water-holding
            capacity, and supports beneficial microbial activity.
          </p>
        </SectionCard>

        <SectionCard imgPath={grassImgPath} sectionStyle="floatRight">
          <h2>Cover Crops</h2>
          <p>
            Above the ground, cover crops protect the soil from the impact of
            wind and rain. And the cover provided by plant leaves shades the
            soil surface, keeping temperatures down for the benefit of the
            microbes that live there. The shade also helps retain soil moisture
            by reducing water loss through evaporation.
          </p>

          <p>
            Below the ground, plants, and grasses in particular, have extensive
            root systems that penetrate deep into the soil. These roots create
            channels and pores, improving soil structure, and promoting aeration
            and water movement. The roots stabilize the soil, preventing
            erosion, and provide an ideal habitat for microbes.
          </p>

          <p>
            Cover crops play a crucial role in nutrient cycling within the soil
            ecosystem. They absorb nutrients from the soil, especially nitrogen
            and phosphorus, and store them in their tissues. When the plants die
            or are grazed upon, these nutrients are returned to the soil through
            decomposition and root exudation, making them available for other
            plants and soil organisms. Grasses have a symbiotic relationship
            with mycorrhizal fungi, which enhance nutrient uptake
          </p>

          <p>
            Cover crops can enhance biodiversity and provide habitat for
            beneficial insects, pollinators, and other wildlife. The diverse
            plant species and the flowering periods of cover crops attract a
            variety of beneficial organisms, including predators and
            pollinators, which contribute to pest control and ecosystem balance.
            This promotes a more resilient and ecologically diverse agricultural
            landscape
          </p>
        </SectionCard>

        <SectionCard
          imgPath={airImgPath}
          innerBox="true"
          sectionStyle="floatRight"
        >
          <h2>Air</h2>
          <p>
            All plants and trees play a role in mitigating climate change by
            sequestering carbon. Through photosynthesis, cover crops capture
            carbon dioxide from the atmosphere and store it in their biomass and
            in the soil. This helps to reduce greenhouse gas emissions and
            contribute to carbon sequestration, making cover cropping a valuable
            practice for climate change mitigation.
          </p>
        </SectionCard>

        <SectionCard imgPath={forestImgPath} sectionStyle="floatRight">
          <h2>Forest</h2>
          <p>
            {' '}
            Forests are incredibly diverse ecosystems, home to a vast array of
            plant and animal species. Healthy forests provide habitats, food
            sources, and shelter for countless organisms, contributing to the
            preservation of biodiversity. Forests support complex food webs and
            ecological interactions, ensuring the survival of various species
            and maintaining ecosystem balance.{' '}
          </p>

          <p>
            Through the process of photosynthesis, trees absorb carbon dioxide
            from the atmosphere and store carbon in their biomass and soils.
            This helps mitigate climate change by reducing the concentration of
            greenhouse gasses. Additionally, forests release oxygen into the
            atmosphere, providing essential air quality benefits.
          </p>

          <p>
            And forests play a crucial role in protecting watersheds. They act
            as natural filters, improving water quality by trapping sediments
            and removing pollutants. They regulate water flow, reducing the risk
            of floods and ensuring a steady water supply during dry periods.
            Forests help recharge groundwater, support stream health, and
            provide clean water for both ecosystems and human communities.
          </p>

          <p>
            Like cover crops, forest ecosystems help prevent soil erosion and
            maintain soil health. The dense canopy of trees in forests protects
            the soil from the impact of rainfall, minimizing erosion caused by
            wind and water. Forest vegetation, leaf litter, and organic matter
            contribute to the development of nutrient-rich soils, supporting
            plant growth and productivity.
          </p>
        </SectionCard>

        <SectionCard
          imgPath={waterImgPath}
          innerBox="true"
          sectionStyle="floatRight"
        >
          <h2>Water</h2>
          <p>
            Healthy soils have good structure and porosity, allowing for better
            water infiltration and retention. Regenerative practices help
            improve soil structure, enabling soils to absorb and hold water more
            effectively.
          </p>
          <p>
            And in recreating healthy plant ecosystems, we can attempt to
            restore the local small water cycle. As plants transpire, the
            evaporative cooling effect regulates their leaf temperature and also
            cools the surrounding air. The water vapor released through
            evapotranspiration from forests rises into the atmosphere, forming
            moist air to be reused by the system, or can condense to form clouds
            which then release precipitation in the form of rain.
          </p>
        </SectionCard>

        <SectionCard imgPath={cowsImgPath} sectionStyle="floatRight">
          <h2>Animal Impact</h2>
          <p>
            Livestock grazing introduces organic matter and nutrients to the
            soil through their manure and urine. This natural fertilization
            process helps replenish nutrient levels in the soil, enhancing
            nutrient cycling. The organic matter from livestock waste
            contributes to the soil's organic carbon content, improving soil
            fertility and supporting beneficial microbial activity.
          </p>
          <p>
            It is essential to implement rotational grazing, manage stocking
            densities, and allow for rest and recovery periods to maximize the
            benefits of integrated livestock grazing while preserving soil
            health and ecosystem integrity.
          </p>
        </SectionCard>
      </div>
    </Layout>
  )
}

export default Regenerate

// Regenerative agriculture is an approach to land management that aims
//             to restore the health and resilience of agricultural ecosystems. It
//             involves practices that promote soil health, biodiversity, water
//             conservation, and overall ecosystem function.
